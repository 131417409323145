var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "telebooking",
      class: { "ticket-change-page": _vm.isShowTicketChange },
    },
    [
      _c("div", { staticClass: "calling" }, [
        _c(
          "div",
          { staticClass: "exit", on: { click: _vm.returnFunHistory } },
          [_c("i", { staticClass: "iconfont its_ba_fanhui" })]
        ),
        _c("header", [_vm._v("改签")]),
        _c("div", { staticClass: "box" }, [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "right",
                    "label-width": "120px",
                    "hide-required-asterisk": _vm.statusCode == "详情",
                  },
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("乘客信息")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "下单人电话：",
                                prop: "passengerPhone",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "请输入来电手机号",
                                  disabled: _vm.computedDisable(),
                                },
                                on: { input: _vm.inputPhone },
                                model: {
                                  value: _vm.form.passengerPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "passengerPhone", $$v)
                                  },
                                  expression: "form.passengerPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "下单类型：",
                                prop: "orderPhone",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.statusCode != "编辑" },
                                  on: {
                                    input: function ($event) {
                                      _vm.form.orderPhone = null
                                    },
                                  },
                                  model: {
                                    value: _vm.form.isPersonal,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isPersonal", $$v)
                                    },
                                    expression: "form.isPersonal",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { value: 1, label: true } },
                                    [_vm._v("本人")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { value: 2, label: false } },
                                    [_vm._v("代买")]
                                  ),
                                ],
                                1
                              ),
                              !_vm.form.isPersonal
                                ? _c("el-input", {
                                    attrs: {
                                      maxlength: "11",
                                      disabled: _vm.statusCode != "编辑",
                                      placeholder: "请输入乘车人手机号",
                                    },
                                    model: {
                                      value: _vm.form.orderPhone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "orderPhone", $$v)
                                      },
                                      expression: "form.orderPhone",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "购票类型：", prop: "realName" },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: false,
                                    disabled: _vm.statusCode != "编辑",
                                  },
                                  model: {
                                    value: _vm.form.realName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "realName", $$v)
                                    },
                                    expression: "form.realName",
                                  },
                                },
                                [_vm._v("匿名")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: true,
                                    disabled: _vm.statusCode != "编辑",
                                  },
                                  model: {
                                    value: _vm.form.realName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "realName", $$v)
                                    },
                                    expression: "form.realName",
                                  },
                                },
                                [_vm._v("实名")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "label" }, [_vm._v("行程信息")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "出发地：",
                                prop: "startStation",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    disabled: _vm.statusCode != "编辑",
                                  },
                                  on: {
                                    focus: _vm.selectStartStation,
                                    change: function ($event) {
                                      return _vm.changeStation(0)
                                    },
                                  },
                                  model: {
                                    value: _vm.form.startStation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "startStation", $$v)
                                    },
                                    expression: "form.startStation",
                                  },
                                },
                                _vm._l(_vm.startStationList, function (item) {
                                  return _c("el-option", {
                                    key: item.stationName,
                                    attrs: {
                                      label: item.stationName,
                                      value: item.stationName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "到达地：", prop: "endStation" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    disabled: _vm.statusCode != "编辑",
                                  },
                                  on: {
                                    focus: _vm.selectEndStation,
                                    change: function ($event) {
                                      return _vm.changeStation(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.form.endStation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "endStation", $$v)
                                    },
                                    expression: "form.endStation",
                                  },
                                },
                                _vm._l(_vm.endStationList, function (item) {
                                  return _c("el-option", {
                                    key: item.stationName,
                                    attrs: {
                                      label: item.stationName,
                                      value: item.stationName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "出行日期：",
                                prop: "departureDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptions,
                                  placeholder: "请选择出行日期",
                                  disabled: _vm.statusCode == "详情",
                                },
                                on: { change: _vm.changeTimeSelect },
                                model: {
                                  value: _vm.form.departureDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "departureDate", $$v)
                                  },
                                  expression: "form.departureDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.statusCode
                        ? _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "10px" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        disabled: _vm.statusCode == "详情",
                                      },
                                      on: { input: _vm.changedepartureDate },
                                      model: {
                                        value: _vm.form.timeSelect,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "timeSelect", $$v)
                                        },
                                        expression: "form.timeSelect",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("今天"),
                                      ]),
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("明天"),
                                      ]),
                                      _c("el-radio", { attrs: { label: 3 } }, [
                                        _vm._v("后天"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "班次时间：",
                                prop: "shiftIntercityId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: _vm.statusCode == "详情" },
                                  on: {
                                    focus: _vm.selectShift,
                                    change: _vm.confirmShift,
                                  },
                                  model: {
                                    value: _vm.form.shiftIntercityId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "shiftIntercityId",
                                        $$v
                                      )
                                    },
                                    expression: "form.shiftIntercityId",
                                  },
                                },
                                _vm._l(_vm.shiftList, function (item) {
                                  return _c("el-option", {
                                    key: item.shiftIntercityId,
                                    attrs: {
                                      disabled: item.disabled,
                                      label: item.label,
                                      value: item.shiftIntercityId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.shiftType === 1
                        ? _c(
                            "el-col",
                            { attrs: { span: 9 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "期望出行时间：",
                                    prop: "anticipationRidingTime",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.form.anticipationRidingTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "anticipationRidingTime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.anticipationRidingTime",
                                      },
                                    },
                                    _vm._l(
                                      _vm.anticipationRidingTimeList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.shiftIntercityId
                        ? _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "span",
                              { staticStyle: { "line-height": "40px" } },
                              [
                                _vm._v(" 此班次剩余携童票 "),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(_vm.surplusCarryChildren)),
                                ]),
                                _vm._v(" 张 "),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.form.realName
                    ? _c(
                        "div",
                        [
                          _vm._l(
                            _vm.form.usedPassengerVOS,
                            function (item, index) {
                              return _c(
                                "el-row",
                                { key: index },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 7 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: index == 0 ? "乘客：" : "",
                                            prop:
                                              "usedPassengerVOS." +
                                              index +
                                              ".userName",
                                            rules: {
                                              required: true,
                                              message: "请输入乘客姓名",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "乘客姓名",
                                              disabled:
                                                _vm.statusCode != "编辑",
                                            },
                                            model: {
                                              value: item.userName,
                                              callback: function ($$v) {
                                                _vm.$set(item, "userName", $$v)
                                              },
                                              expression: "item.userName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 7 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "20px",
                                            prop:
                                              "usedPassengerVOS." +
                                              index +
                                              ".idCardNo",
                                            rules: {
                                              required: true,
                                              validator: (
                                                rule,
                                                value,
                                                callback
                                              ) => {
                                                _vm.checkIdentificationNumber(
                                                  rule,
                                                  value,
                                                  callback
                                                )
                                              },
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "乘客身份证号",
                                              disabled:
                                                _vm.statusCode != "编辑",
                                            },
                                            model: {
                                              value: item.idCardNo,
                                              callback: function ($$v) {
                                                _vm.$set(item, "idCardNo", $$v)
                                              },
                                              expression: "item.idCardNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "usedPassengerVOS." +
                                              index +
                                              ".segment",
                                            "label-width": "20px",
                                            rules: {
                                              required: true,
                                              message: "请选择乘客类型",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.statusCode != "编辑",
                                              },
                                              on: {
                                                change: (e) =>
                                                  _vm.selectSegment(e, item),
                                              },
                                              model: {
                                                value: item.segment,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "segment", $$v)
                                                },
                                                expression: "item.segment",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: 1,
                                                  label: "成人",
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  value: 0,
                                                  label: "儿童",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.statusCode
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 5 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { "label-width": "20px" },
                                            },
                                            [
                                              item.segment
                                                ? _c(
                                                    "el-checkbox",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.statusCode !=
                                                          "编辑",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickChildren(
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.isCarryingChildren,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isCarryingChildren",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isCarryingChildren",
                                                      },
                                                    },
                                                    [_vm._v("携童")]
                                                  )
                                                : _vm._e(),
                                              _vm.form.usedPassengerVOS.length >
                                                1 && _vm.statusCode == "编辑"
                                                ? _c("i", {
                                                    staticClass:
                                                      "iconfont its_gy_delete",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteVisitor(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          _vm.statusCode == "编辑"
                            ? _c(
                                "div",
                                {
                                  staticClass: "add",
                                  on: { click: _vm.addNew },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont its_tianjia",
                                    staticStyle: { "margin-right": "10px" },
                                  }),
                                  _vm._v(" 添加新乘客 "),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "乘客人数：",
                                    prop: "passengerCount",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      size: "small",
                                      min: 1,
                                      disabled: _vm.statusCode != "编辑",
                                    },
                                    on: { change: _vm.selectValPer },
                                    model: {
                                      value: _vm.form.passengerCount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "passengerCount",
                                          $$v
                                        )
                                      },
                                      expression: "form.passengerCount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "携童数量：",
                                    prop: "carryingChildrenNum",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      size: "small",
                                      min: 0,
                                      max:
                                        _vm.form.passengerCount >=
                                        _vm.surplusCarryChildren
                                          ? _vm.surplusCarryChildren
                                          : _vm.form.passengerCount,
                                      disabled: _vm.statusCode != "编辑",
                                    },
                                    model: {
                                      value: _vm.form.carryingChildrenNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "carryingChildrenNum",
                                          $$v
                                        )
                                      },
                                      expression: "form.carryingChildrenNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 19 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "上车点：",
                                prop: "startAddress",
                              },
                            },
                            [
                              _c("div", { staticClass: "address" }, [
                                _c(
                                  "div",
                                  { staticClass: "editbox" },
                                  [
                                    !_vm.upStationList.length
                                      ? _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请输入上车点",
                                              filterable: "",
                                              remote: "",
                                              "reserve-keyword": "",
                                              "remote-method": (e) =>
                                                _vm.remoteAddress(e, 0),
                                              disabled:
                                                _vm.statusCode != "编辑" &&
                                                _vm.sameLine,
                                            },
                                            on: {
                                              focus: _vm.focusAddress,
                                              change: _vm.selectAreaPoint,
                                            },
                                            model: {
                                              value: _vm.form.startAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "startAddress",
                                                  $$v
                                                )
                                              },
                                              expression: "form.startAddress",
                                            },
                                          },
                                          _vm._l(
                                            _vm.addressList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.address,
                                                  value: item.address,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择上车点",
                                              disabled:
                                                _vm.statusCode != "编辑" &&
                                                _vm.sameLine,
                                            },
                                            on: {
                                              change: (e) =>
                                                _vm.selectFixedStation(e, 0),
                                            },
                                            model: {
                                              value: _vm.form.startAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "startAddress",
                                                  $$v
                                                )
                                              },
                                              expression: "form.startAddress",
                                            },
                                          },
                                          _vm._l(
                                            _vm.upStationList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                    _vm.statusCode == "编辑" || !_vm.sameLine
                                      ? _c("i", {
                                          staticClass: "iconfont its_gy_map",
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectAddress(0)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.statusCode == "编辑" || !_vm.sameLine
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        _vm.upCatchList,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectCatchAddress(
                                                    item,
                                                    0
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.address) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 19 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "下车点：", prop: "endAddress" },
                            },
                            [
                              _c("div", { staticClass: "address" }, [
                                _c(
                                  "div",
                                  { staticClass: "editbox" },
                                  [
                                    !_vm.downStationList.length
                                      ? _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请输入下车点",
                                              filterable: "",
                                              remote: "",
                                              "reserve-keyword": "",
                                              "remote-method": (e) =>
                                                _vm.remoteAddress(e, 1),
                                              disabled:
                                                _vm.statusCode != "编辑" &&
                                                _vm.sameLine,
                                            },
                                            on: {
                                              focus: _vm.focusAddress,
                                              change: _vm.selectAreaPoint,
                                            },
                                            model: {
                                              value: _vm.form.endAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "endAddress",
                                                  $$v
                                                )
                                              },
                                              expression: "form.endAddress",
                                            },
                                          },
                                          _vm._l(
                                            _vm.addressList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.address,
                                                  value: item.address,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择下车点",
                                              disabled:
                                                _vm.statusCode != "编辑" &&
                                                _vm.sameLine,
                                            },
                                            on: {
                                              change: (e) =>
                                                _vm.selectFixedStation(e, 1),
                                            },
                                            model: {
                                              value: _vm.form.endAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "endAddress",
                                                  $$v
                                                )
                                              },
                                              expression: "form.endAddress",
                                            },
                                          },
                                          _vm._l(
                                            _vm.downStationList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                    _vm.statusCode == "编辑" || !_vm.sameLine
                                      ? _c("i", {
                                          staticClass: "iconfont its_gy_map",
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectAddress(1)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.statusCode == "编辑" || !_vm.sameLine
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        _vm.downCatchList,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectCatchAddress(
                                                    item,
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.address) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 19 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入备注",
                                  type: "textarea",
                                  disabled: _vm.statusCode != "编辑",
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "submit" }, [
                _vm.statusCode != "详情"
                  ? _c("div", { staticClass: "btns" }, [
                      !_vm.disabledPlaceOrder
                        ? _c(
                            "button",
                            {
                              class: ["order", _vm.loading2 ? "cancel" : ""],
                              on: { click: _vm.placeOrder },
                            },
                            [_vm._v(" 立即下单 ")]
                          )
                        : _vm._e(),
                      _vm.statusCode == "改签"
                        ? _c(
                            "button",
                            {
                              staticClass: "cancel",
                              on: { click: _vm.cancelOrder },
                            },
                            [_vm._v(" 取消 ")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "total" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-right": "28px" },
                    },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("票价：")]),
                      _c(
                        "div",
                        { staticStyle: { color: "#000", "margin-top": "5px" } },
                        [_vm._v(" " + _vm._s(_vm.ticketPrice) + " 元/张 ")]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("应付总额：")]),
                    _c("div", { staticClass: "price" }, [
                      _vm._v("￥" + _vm._s(_vm.form.totalPrices)),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "table" }, [
            _c("div", { staticClass: "label" }, [_vm._v("历史订单")]),
            _vm.orderList.length
              ? _c(
                  "div",
                  { staticClass: "order-list" },
                  _vm._l(_vm.orderList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", { staticClass: "header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "sign",
                            style: {
                              backgroundColor:
                                item.orderStatus == 70
                                  ? "#00C777"
                                  : item.orderStatus == 60
                                  ? "#FF8627"
                                  : "#336FFE",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.showStatus(item.orderStatus)) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "line" }, [
                          _c("p", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.mainLineName)),
                          ]),
                          _c("p", { staticClass: "status" }, [
                            _vm._v(_vm._s(_vm.showStatus(item.orderStatus))),
                          ]),
                        ]),
                        _c("div", { staticClass: "btns" }),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("label", [_vm._v("出行时间")]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(item.departureDate) +
                              " " +
                              _vm._s(_vm.computedAnticipationRidingTime(item)) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("label", [_vm._v("上车点")]),
                        _c("p", [
                          _vm._v(_vm._s(item.startAddress.split("|")[0])),
                        ]),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("label", [_vm._v("下车点")]),
                        _c("p", [
                          _vm._v(_vm._s(item.endAddress.split("|")[0])),
                        ]),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("label", [_vm._v("下单日期")]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.showCreateTime(item.orderTime))),
                        ]),
                      ]),
                    ])
                  }),
                  0
                )
              : _c("div", [
                  _c("p", { staticClass: "empty1" }, [
                    _vm._v("未查询到历史订单"),
                  ]),
                  _c("p", { staticClass: "empty2" }, [
                    _vm._v("该电话号码暂无历史订单，请下个订单试试"),
                  ]),
                ]),
          ]),
        ]),
      ]),
      _vm.isShowTicketChange
        ? _c(
            "div",
            { staticClass: "ticket-change" },
            [
              _c("div", { staticClass: "exit", on: { click: _vm.returnFun } }, [
                _c("i", { staticClass: "iconfont its_ba_fanhui" }),
              ]),
              _c("div", { staticClass: "title" }, [_vm._v("改签")]),
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.ticketChangeActive,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", {
                    attrs: {
                      title: "确认改签信息",
                      description: "请确认乘客信息是否有误",
                    },
                  }),
                  _c("el-step", {
                    attrs: {
                      title:
                        _vm.ticketChangeActive == 0 ? "改签班次" : "确认新班次",
                      description: "修改新的班次",
                    },
                  }),
                  _c("el-step", { attrs: { title: "完成", description: "" } }),
                ],
                1
              ),
              _vm.ticketChangeActive !== 2
                ? _c("phoneOrderDetial", {
                    ref: "phoneOrderDetialRef",
                    attrs: {
                      from: "ticketChange",
                      step: _vm.ticketChangeActive,
                      ticketChangeForm: _vm.ticketChangeForm,
                    },
                    on: {
                      placeOrder: _vm.placeOrder2,
                      watchOrderAmount: _vm.watchOrderAmount,
                    },
                  })
                : _c("div", { staticClass: "result-box" }, [
                    _vm.isTicketChange
                      ? _c("div", { staticClass: "success-text" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/success.svg"),
                            },
                          }),
                          _c("span", [_vm._v("改签成功")]),
                        ])
                      : _c("div", { staticClass: "error-text" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/error.svg"),
                            },
                          }),
                          _c("span", [_vm._v("改签失败")]),
                        ]),
                  ]),
              _c("div", { staticClass: "button-box" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 0,
                        expression: "ticketChangeActive == 0",
                      },
                    ],
                  },
                  [
                    _c("el-button", { on: { click: _vm.returnFun } }, [
                      _vm._v("取消并返回"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.nextFun },
                      },
                      [_vm._v("信息确认无误")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 1,
                        expression: "ticketChangeActive == 1",
                      },
                    ],
                  },
                  [
                    _c("el-button", { on: { click: _vm.previousFun } }, [
                      _vm._v("上一步"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.isDisabledConfirmTicketFlag,
                        },
                        on: { click: _vm.confirmTicketChangeFun },
                      },
                      [_vm._v("确认改签")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 2,
                        expression: "ticketChangeActive == 2",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.returnFun("request")
                          },
                        },
                      },
                      [_vm._v("返回上一页")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.showMap
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.addressType ? "选择下车点" : "选择上车点",
                visible: _vm.showMap,
                width: "1000px",
                "close-on-click-modal": false,
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showMap = $event
                },
                close: _vm.cancelBtn,
              },
            },
            [
              _c("div", { staticClass: "map" }, [
                _vm.areaMode == 0
                  ? _c("div", { staticClass: "searchbox" }, [
                      _c(
                        "header",
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchInput,
                                expression: "searchInput",
                              },
                            ],
                            attrs: { type: "text", placeholder: "搜索地址" },
                            domProps: { value: _vm.searchInput },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.searchInput = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "el-button",
                            { attrs: { type: "primary", size: "small" } },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pointlist" },
                        _vm._l(_vm.addressList, function (item, index) {
                          return _c(
                            "p",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.searchClick(item, _vm.addressType)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.address) + " ")]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "select",
                    style: { top: _vm.areaMode == 0 ? "55px" : "14px" },
                  },
                  [
                    _c("header", [_vm._v("请选择地点")]),
                    _vm.areaMode == 0
                      ? _c("p", [_vm._v(_vm._s(_vm.searchAddress))])
                      : _vm._e(),
                    _vm.areaMode == 1
                      ? _c(
                          "div",
                          { staticClass: "point" },
                          _vm._l(_vm.pointList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "item",
                                class: { item1: _vm.currentIndex === index },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickPoint(item, index)
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "detial",
                                    attrs: { title: item.address },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.detialAddress.split("|")[1]
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
                _c("div", { attrs: { id: "container" } }),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.cancelBtn },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.confirmAddress },
                    },
                    [_vm._v("选择")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.showRefund
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.showRefund, width: "800px", title: "退票" },
              on: {
                "update:visible": function ($event) {
                  _vm.showRefund = $event
                },
                close: _vm.refundCloseFun,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "refund-box",
                  staticStyle: { margin: "0 40px" },
                },
                [
                  _c("Table", {
                    attrs: {
                      "table-data": _vm.tableData,
                      "title-name": _vm.titleName,
                      "type-checkbox": true,
                      "table-height": 200,
                    },
                    on: { "select-all": _vm.selectRefundList },
                  }),
                  _c("div", { staticClass: "feeBox" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("退票手续费(%)")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "charterSelect",
                            on: { change: _vm.refundServiveFeeChange },
                            model: {
                              value: _vm.refundForm.serviceCharge,
                              callback: function ($$v) {
                                _vm.$set(_vm.refundForm, "serviceCharge", $$v)
                              },
                              expression: "refundForm.serviceCharge",
                            },
                          },
                          _vm._l(_vm.serviceChargeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("退票金额")]),
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.refundForm.refundTotal,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundForm, "refundTotal", $$v)
                            },
                            expression: "refundForm.refundTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "reasonBox" },
                    [
                      _c("p", [_vm._v("原因")]),
                      _c("el-input", {
                        staticClass: "textareaInputs",
                        attrs: {
                          type: "textarea",
                          placeholder:
                            "填写原因时请注意，该信息将以短信的形式发送给用户。",
                        },
                        model: {
                          value: _vm.refundForm.reasonForRefund,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.refundForm,
                              "reasonForRefund",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "refundForm.reasonForRefund",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      " 点击确认按钮提交退款申请后， 需等待当前弹窗消失， 期间请勿进行其它操作 "
                    ),
                  ]),
                  _c("el-button", { on: { click: _vm.refundCloseFun } }, [
                    _vm._v("取消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.loading },
                      on: { click: _vm.refundConfirmFun },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }