<template>
  <div
    class="telebooking"
    :class="{ 'ticket-change-page': isShowTicketChange }"
  >
    <div class="calling">
      <div class="exit" @click="returnFunHistory">
        <i class="iconfont its_ba_fanhui"></i>
      </div>
      <header>改签</header>
      <div class="box">
        <div class="form">
          <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-position="right"
            label-width="120px"
            :hide-required-asterisk="statusCode == '详情'"
          >
            <div class="label">乘客信息</div>
            <el-row>
              <el-col :span="18">
                <el-form-item label="下单人电话：" prop="passengerPhone">
                  <el-input
                    v-model="form.passengerPhone"
                    maxlength="11"
                    placeholder="请输入来电手机号"
                    :disabled="computedDisable()"
                    @input="inputPhone"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="下单类型：" prop="orderPhone">
                  <el-radio-group
                    v-model="form.isPersonal"
                    :disabled="statusCode != '编辑'"
                    @input="form.orderPhone = null"
                  >
                    <el-radio :value="1" :label="true">本人</el-radio>
                    <el-radio :value="2" :label="false">代买</el-radio>
                  </el-radio-group>
                  <el-input
                    v-if="!form.isPersonal"
                    v-model="form.orderPhone"
                    maxlength="11"
                    :disabled="statusCode != '编辑'"
                    placeholder="请输入乘车人手机号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="购票类型：" prop="realName">
                  <el-radio
                    v-model="form.realName"
                    :label="false"
                    :disabled="statusCode != '编辑'"
                    >匿名</el-radio
                  >
                  <el-radio
                    v-model="form.realName"
                    :label="true"
                    :disabled="statusCode != '编辑'"
                    >实名</el-radio
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <div class="label">行程信息</div>
            <el-row>
              <el-col :span="10">
                <el-form-item label="出发地：" prop="startStation">
                  <el-select
                    v-model="form.startStation"
                    filterable
                    clearable
                    :disabled="statusCode != '编辑'"
                    @focus="selectStartStation"
                    @change="changeStation(0)"
                  >
                    <el-option
                      v-for="item in startStationList"
                      :key="item.stationName"
                      :label="item.stationName"
                      :value="item.stationName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="到达地：" prop="endStation">
                  <el-select
                    v-model="form.endStation"
                    filterable
                    clearable
                    :disabled="statusCode != '编辑'"
                    @focus="selectEndStation"
                    @change="changeStation(1)"
                  >
                    <el-option
                      v-for="item in endStationList"
                      :key="item.stationName"
                      :label="item.stationName"
                      :value="item.stationName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="出行日期：" prop="departureDate">
                  <el-date-picker
                    v-model="form.departureDate"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    placeholder="请选择出行日期"
                    :disabled="statusCode == '详情'"
                    @change="changeTimeSelect"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col v-if="statusCode" :span="10">
                <el-form-item label-width="10px">
                  <el-radio-group
                    v-model="form.timeSelect"
                    :disabled="statusCode == '详情'"
                    @input="changedepartureDate"
                  >
                    <el-radio :label="1">今天</el-radio>
                    <el-radio :label="2">明天</el-radio>
                    <el-radio :label="3">后天</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="班次时间：" prop="shiftIntercityId">
                  <el-select
                    v-model="form.shiftIntercityId"
                    :disabled="statusCode == '详情'"
                    @focus="selectShift"
                    @change="confirmShift"
                  >
                    <el-option
                      v-for="item in shiftList"
                      :key="item.shiftIntercityId"
                      :disabled="item.disabled"
                      :label="item.label"
                      :value="item.shiftIntercityId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="9" v-if="form.shiftType === 1">
                <el-form-item
                  label="期望出行时间："
                  prop="anticipationRidingTime"
                >
                  <el-select v-model="form.anticipationRidingTime">
                    <el-option
                      v-for="item in anticipationRidingTimeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4" v-if="form.shiftIntercityId">
                <span style="line-height: 40px">
                  此班次剩余携童票
                  <span style="color: red">{{ surplusCarryChildren }}</span>
                  张
                </span>
              </el-col>
            </el-row>

            <div v-if="form.realName">
              <el-row
                v-for="(item, index) in form.usedPassengerVOS"
                :key="index"
              >
                <el-col :span="7">
                  <el-form-item
                    :label="index == 0 ? '乘客：' : ''"
                    :prop="'usedPassengerVOS.' + index + '.userName'"
                    :rules="{
                      required: true,
                      message: '请输入乘客姓名',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="item.userName"
                      placeholder="乘客姓名"
                      :disabled="statusCode != '编辑'"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item
                    label-width="20px"
                    :prop="'usedPassengerVOS.' + index + '.idCardNo'"
                    :rules="{
                      required: true,
                      validator: (rule, value, callback) => {
                        checkIdentificationNumber(rule, value, callback)
                      },
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="item.idCardNo"
                      placeholder="乘客身份证号"
                      :disabled="statusCode != '编辑'"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    :prop="'usedPassengerVOS.' + index + '.segment'"
                    label-width="20px"
                    :rules="{
                      required: true,
                      message: '请选择乘客类型',
                      trigger: 'blur',
                    }"
                  >
                    <el-select
                      v-model="item.segment"
                      :disabled="statusCode != '编辑'"
                      @change="(e) => selectSegment(e, item)"
                    >
                      <el-option :value="1" label="成人" />
                      <el-option :value="0" label="儿童" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col v-if="statusCode" :span="5">
                  <el-form-item label-width="20px">
                    <el-checkbox
                      v-if="item.segment"
                      v-model="item.isCarryingChildren"
                      :disabled="statusCode != '编辑'"
                      @change="clickChildren(index)"
                      >携童</el-checkbox
                    >
                    <i
                      v-if="
                        form.usedPassengerVOS.length > 1 && statusCode == '编辑'
                      "
                      class="iconfont its_gy_delete"
                      @click="deleteVisitor(index)"
                    ></i>
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-if="statusCode == '编辑'" class="add" @click="addNew">
                <i class="iconfont its_tianjia" style="margin-right: 10px"></i>
                添加新乘客
              </div>
            </div>
            <el-row v-else>
              <el-col :span="10">
                <el-form-item label="乘客人数：" prop="passengerCount">
                  <el-input-number
                    v-model="form.passengerCount"
                    size="small"
                    :min="1"
                    :disabled="statusCode != '编辑'"
                    @change="selectValPer"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="携童数量：" prop="carryingChildrenNum">
                  <el-input-number
                    v-model="form.carryingChildrenNum"
                    size="small"
                    :min="0"
                    :max="
                      form.passengerCount >= surplusCarryChildren
                        ? surplusCarryChildren
                        : form.passengerCount
                    "
                    :disabled="statusCode != '编辑'"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="19">
                <el-form-item label="上车点：" prop="startAddress">
                  <div class="address">
                    <div class="editbox">
                      <el-select
                        v-if="!upStationList.length"
                        v-model="form.startAddress"
                        placeholder="请输入上车点"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="(e) => remoteAddress(e, 0)"
                        style="width: 100%"
                        :disabled="statusCode != '编辑' && sameLine"
                        @focus="focusAddress"
                        @change="selectAreaPoint"
                      >
                        <el-option
                          v-for="(item, index) in addressList"
                          :key="index"
                          :label="item.address"
                          :value="item.address"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-else
                        v-model="form.startAddress"
                        placeholder="请选择上车点"
                        style="width: 100%"
                        :disabled="statusCode != '编辑' && sameLine"
                        @change="(e) => selectFixedStation(e, 0)"
                      >
                        <el-option
                          v-for="(item, index) in upStationList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                      <i
                        v-if="statusCode == '编辑' || !sameLine"
                        class="iconfont its_gy_map"
                        @click="selectAddress(0)"
                      ></i>
                    </div>
                    <ul v-if="statusCode == '编辑' || !sameLine">
                      <li
                        v-for="(item, index) in upCatchList"
                        :key="index"
                        @click="selectCatchAddress(item, 0)"
                      >
                        {{ item.address }}
                      </li>
                    </ul>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="19">
                <el-form-item label="下车点：" prop="endAddress">
                  <div class="address">
                    <div class="editbox">
                      <el-select
                        v-if="!downStationList.length"
                        v-model="form.endAddress"
                        placeholder="请输入下车点"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="(e) => remoteAddress(e, 1)"
                        style="width: 100%"
                        :disabled="statusCode != '编辑' && sameLine"
                        @focus="focusAddress"
                        @change="selectAreaPoint"
                      >
                        <el-option
                          v-for="(item, index) in addressList"
                          :key="index"
                          :label="item.address"
                          :value="item.address"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-else
                        v-model="form.endAddress"
                        placeholder="请选择下车点"
                        style="width: 100%"
                        :disabled="statusCode != '编辑' && sameLine"
                        @change="(e) => selectFixedStation(e, 1)"
                      >
                        <el-option
                          v-for="(item, index) in downStationList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                      <i
                        v-if="statusCode == '编辑' || !sameLine"
                        class="iconfont its_gy_map"
                        @click="selectAddress(1)"
                      ></i>
                    </div>
                    <ul v-if="statusCode == '编辑' || !sameLine">
                      <li
                        v-for="(item, index) in downCatchList"
                        :key="index"
                        @click="selectCatchAddress(item, 1)"
                      >
                        {{ item.address }}
                      </li>
                    </ul>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="19">
                <el-form-item label="备注：">
                  <el-input
                    v-model="form.remarks"
                    placeholder="请输入备注"
                    type="textarea"
                    :disabled="statusCode != '编辑'"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="submit">
            <div v-if="statusCode != '详情'" class="btns">
              <button
                v-if="!disabledPlaceOrder"
                :class="['order', loading2 ? 'cancel' : '']"
                @click="placeOrder"
              >
                立即下单
              </button>
              <button
                v-if="statusCode == '改签'"
                class="cancel"
                @click="cancelOrder"
              >
                取消
              </button>
            </div>
            <div class="total">
              <div style="margin-right: 28px" class="item">
                <div class="title">票价：</div>
                <div style="color: #000; margin-top: 5px">
                  {{ ticketPrice }} 元/张
                </div>
              </div>
              <div class="item">
                <div class="title">应付总额：</div>
                <div class="price">￥{{ form.totalPrices }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="table">
          <div class="label">历史订单</div>
          <div v-if="orderList.length" class="order-list">
            <div v-for="(item, index) in orderList" :key="index" class="item">
              <div class="header">
                <div
                  class="sign"
                  :style="{
                    backgroundColor:
                      item.orderStatus == 70
                        ? '#00C777'
                        : item.orderStatus == 60
                        ? '#FF8627'
                        : '#336FFE',
                  }"
                >
                  {{ showStatus(item.orderStatus) }}
                </div>
                <div class="line">
                  <p class="name">{{ item.mainLineName }}</p>
                  <p class="status">{{ showStatus(item.orderStatus) }}</p>
                </div>
                <div class="btns">
                  <!-- <el-button
                    type="primary"
                    size="mini"
                    @click="useHistoryOrder(item)"
                  >
                    <i class="iconfont its_copy"></i>
                    复用此订单</el-button
                  > -->
                  <!-- <el-button
                    type="primary"
                    size="mini"
                    :disabled="
                      [70, 60, 110, 120, 130].includes(item.orderStatus) ||
                      item.purchaseMethod != 4
                    "
                    @click="refundTicket(item)"
                    >退票</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    :disabled="
                      [80, 70, 60, 110, 120, 130].includes(item.orderStatus) ||
                      item.purchaseMethod != 4
                    "
                    @click="changeTicket(item)"
                    >改签</el-button
                  > -->
                </div>
              </div>
              <div class="info">
                <label>出行时间</label>
                <p>
                  {{ item.departureDate }} {{ computedAnticipationRidingTime(item) }}
                </p>
              </div>
              <div class="info">
                <label>上车点</label>
                <p>{{ item.startAddress.split('|')[0] }}</p>
              </div>
              <div class="info">
                <label>下车点</label>
                <p>{{ item.endAddress.split('|')[0] }}</p>
              </div>
              <div class="info">
                <label>下单日期</label>
                <p>{{ showCreateTime(item.orderTime) }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="empty1">未查询到历史订单</p>
            <p class="empty2">该电话号码暂无历史订单，请下个订单试试</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowTicketChange" class="ticket-change">
      <div class="exit" @click="returnFun">
        <i class="iconfont its_ba_fanhui"></i>
      </div>
      <div class="title">改签</div>
      <el-steps
        :active="ticketChangeActive"
        align-center
        finish-status="success"
      >
        <el-step
          title="确认改签信息"
          description="请确认乘客信息是否有误"
        ></el-step>
        <el-step
          :title="ticketChangeActive == 0 ? '改签班次' : '确认新班次'"
          description="修改新的班次"
        ></el-step>
        <el-step title="完成" description></el-step>
      </el-steps>
      <phoneOrderDetial
        ref="phoneOrderDetialRef"
        v-if="ticketChangeActive !== 2"
        from="ticketChange"
        :step="ticketChangeActive"
        :ticketChangeForm="ticketChangeForm"
        @placeOrder="placeOrder2"
        @watchOrderAmount="watchOrderAmount"
      ></phoneOrderDetial>
      <div v-else class="result-box">
        <div v-if="isTicketChange" class="success-text">
          <img src="~@/assets/images/home/success.svg" />
          <span>改签成功</span>
        </div>
        <div v-else class="error-text">
          <img src="~@/assets/images/home/error.svg" />
          <span>改签失败</span>
        </div>
      </div>
      <div class="button-box">
        <div v-show="ticketChangeActive == 0">
          <el-button @click="returnFun">取消并返回</el-button>
          <el-button type="primary" @click="nextFun">信息确认无误</el-button>
        </div>
        <div v-show="ticketChangeActive == 1">
          <el-button @click="previousFun">上一步</el-button>
          <el-button
            type="primary"
            :disabled="isDisabledConfirmTicketFlag"
            @click="confirmTicketChangeFun"
            >确认改签</el-button
          >
        </div>
        <div v-show="ticketChangeActive == 2">
          <el-button type="primary" @click="returnFun('request')"
            >返回上一页</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      v-if="showMap"
      :title="addressType ? '选择下车点' : '选择上车点'"
      :visible.sync="showMap"
      width="1000px"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="cancelBtn"
    >
      <div class="map">
        <div v-if="areaMode == 0" class="searchbox">
          <header>
            <input v-model="searchInput" type="text" placeholder="搜索地址" />
            <el-button type="primary" size="small">搜索</el-button>
          </header>
          <div class="pointlist">
            <p
              v-for="(item, index) in addressList"
              :key="index"
              @click="searchClick(item, addressType)"
            >
              {{ item.address }}
            </p>
          </div>
        </div>
        <div class="select" :style="{ top: areaMode == 0 ? '55px' : '14px' }">
          <header>请选择地点</header>
          <p v-if="areaMode == 0">{{ searchAddress }}</p>
          <div v-if="areaMode == 1" class="point">
            <div
              v-for="(item, index) in pointList"
              :key="index"
              class="item"
              :class="{ item1: currentIndex === index }"
              @click="clickPoint(item, index)"
            >
              <span>{{ item.name }}</span>
              <span class="detial" :title="item.address">
                {{ item.detialAddress.split('|')[1] }}
              </span>
            </div>
          </div>
        </div>
        <div id="container" />
      </div>
      <span v-cloak slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="cancelBtn"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="confirmAddress"
          >选择</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-if="showRefund"
      :visible.sync="showRefund"
      width="800px"
      title="退票"
      @close="refundCloseFun"
    >
      <div style="margin: 0 40px" class="refund-box">
        <Table
          :table-data="tableData"
          :title-name="titleName"
          :type-checkbox="true"
          :table-height="200"
          @select-all="selectRefundList"
        />
        <div class="feeBox">
          <div>
            <p>退票手续费(%)</p>
            <el-select
              v-model="refundForm.serviceCharge"
              class="charterSelect"
              @change="refundServiveFeeChange"
            >
              <el-option
                v-for="item in serviceChargeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <p>退票金额</p>
            <el-input v-model="refundForm.refundTotal" disabled />
          </div>
        </div>
        <div class="reasonBox">
          <p>原因</p>
          <el-input
            v-model.trim="refundForm.reasonForRefund"
            class="textareaInputs"
            type="textarea"
            placeholder="填写原因时请注意，该信息将以短信的形式发送给用户。"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="footer">
        <p class="desc">
          点击确认按钮提交退款申请后， 需等待当前弹窗消失， 期间请勿进行其它操作
        </p>
        <el-button @click="refundCloseFun">取消</el-button>
        <el-button type="primary" :disabled="loading" @click="refundConfirmFun"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { checkPhone } from "@/common/utils/index.js";
import moment from "moment";
import {
  queryElectricCallingPage,
  placeOrderAPI,
  searchShiftTimeAPI,
  searchLineAPI,
  configureParamsAPI,
  queryAreaList,
  queryKkPassengerCacheList,
  searchPointAPI,
  cancelTicket,
  getOrderInfoAPI,
  saveOrUpdate,
  reschedulingAPI,
} from "@/api/lib/api";
import axios from "axios";
import cryptoJs from "crypto-js";
import phoneOrderDetial from "./phoneOrderDetial.vue";
export default {
  components: { phoneOrderDetial },
  data () {
    return {
      form: {
        orderPhone: null,
        passengerPhone: null,
        isPersonal: true,
        realName: false,
        startStation: null,
        endStation: null,
        departureDate: moment().format("YYYY-MM-DD"),
        timeSelect: 1,
        shiftIntercityId: null,
        passengerCount: 1,
        carryingChildrenNum: 0,
        usedPassengerVOS: [{ userName: "", idCardNo: "", segment: "" }],
        startAddress: null,
        startPointLongitude: null,
        startPointLatitude: null,
        startLineAreaId: null,
        startStationId: null,
        startStationType: null,
        endAddress: null,
        endPointLongitude: null,
        endPointLatitude: null,
        endStationId: null,
        endStationType: null,
        endLineAreaId: null,
        remarks: null,
        totalPrices: 0,
        ticketPricesPer: 0, //一人该给的钱
        autoSeatSelection: true,
        servicePrice: 0,
        anticipationRidingTime: "",
      },
      usedForm: {},
      rules: {
        passengerPhone: {
          required: true,
          validator: (rule, value, callback) => {
            checkPhone(rule, value, callback);
          },
          trigger: "blur",
        },
        orderPhone: {
          required: true,
          validator: (rule, value, callback) => {
            if (this.form.isPersonal) callback();
            else checkPhone(rule, value, callback);
          },
          trigger: "blur",
        },
        startStation: {
          required: true,
          message: "请选择出发地",
          trigger: "blur",
        },
        endStation: {
          required: true,
          message: "请选择到达地",
          trigger: "blur",
        },
        departureDate: {
          required: true,
          message: "请选择出行日期",
          trigger: "blur",
        },
        shiftIntercityId: {
          required: true,
          message: "请选择班次时间",
          trigger: "blur",
        },
        anticipationRidingTime: {
          required: true,
          message: "请选择出行时间",
          trigger: "blur",
        },
        usedPassengerVOS: [{ userName: "", idCardNo: "", segment: "" }],
        startAddress: [
          {
            required: true,
            message: "请输入上车点",
            trigger: "blur",
          },
          {
            required: true,
            message: "请选择上车点",
            trigger: "blur",
          },
        ],
        endAddress: [
          {
            required: true,
            message: "请输入下车点",
            trigger: "blur",
          },
          {
            required: true,
            message: "请选择下车点",
            trigger: "blur",
          },
        ],
      },
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
      statusCode: "编辑",
      startStationList: [],
      endStationList: [],
      shiftList: [],
      surplusCarryChildren: 0, //班次剩余携童票数
      lineId: null, //选择的线路id
      upCatchList: [], //上车点缓存站点列表
      downCatchList: [], //下车点缓存站点列表
      copyUpCatchList: [], //上车点缓存站点列表
      copyDownCatchList: [], //下车点缓存站点列表
      cacheId: null, //缓存数据id
      upStationList: [], //上车点是固定点站点列表
      downStationList: [], //下车点是固定点站点列表
      upAreaList: [], //上车点是区域站点列表
      downAreaList: [], //下车点是区域站点列表
      showMap: false,
      addressType: null,
      areaMode: null,
      map: null,
      pickerPosition: null,
      positionLatLng: null,
      marker: null,
      areaPath: [],
      searchInput: null,
      addressList: [], //地图搜索出的地点列表
      pointList: [], //固定点列表
      currentIndex: -1,
      searchAddress: null,
      selectStartPoint: {
        //选择的上车点
        name: null,
        address: null,
        detialAddress: null,
        lng: null,
        lat: null,
        areaType: null,
        stationId: null,
        id: null,
        floatingPrice: 0,
        price: 0,
      },
      selectEndPoint: {
        //选择的下车点
        name: null,
        address: null,
        detialAddress: null,
        lng: null,
        lat: null,
        areaType: null,
        stationId: null,
        id: null,
        floatingPrice: 0,
        price: 0,
      },
      upFloatingPrice: 0, //上车点溢价
      downFloatingPrice: 0, //下车点溢价
      price: 0, //票价
      ticketPrice: 0,
      showPrice: true,
      orderList: [],
      tableData: [],
      titleName: [
        {
          title: "票号",
          props: "id",
          width: "200",
        },
        {
          title: "乘客类型",
          props: "segment",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : "成人";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "身份证号码",
          width: 120,
          props: "idCardNo",
          SpecialJudgment: (res) => {
            return res == "匿名用户"
              ? res
              : this.decryptDes(res).replace(
                /^(.{4})(?:\d+)(.{3})$/,
                "$1******$2"
              );
          },
        },
        {
          title: "是否携童",
          props: "carryingChildren",
          SpecialJudgment: (res) => (res ? "是" : "否"),
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "支付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.orderStatusList.find((t) => t.value == res).label;
          },
        },
      ],
      orderStatusList: [
        { value: 10, label: "待支付" },
        { value: 20, label: "待配车" },
        { value: 30, label: "待派单" },
        { value: 40, label: "待接驾" },
        { value: 50, label: "司机申请取消接驾" },
        { value: 60, label: "已接驾" },
        { value: 70, label: "已完成" },
        { value: 80, label: "取消接驾" },
        { value: 110, label: "订单取消" },
        { value: 120, label: "已退票" },
        { value: 130, label: "已改签" },
      ],
      showRefund: false,
      refundForm: {
        serviceCharge: 0,
        refundTotal: 0,
        reasonForRefund: "",
      },
      refundList: [],
      loading: false,
      loading2: false,
      refundChildrenNum: 0, //退票携童票数
      serviceChargeList: [{ label: "0", value: 0 }],
      //改签数据
      isShowTicketChange: false, //是否显示改签页面
      ticketChangeActive: 0, //改签步骤条
      ticketChangeForm: {},
      isTicketChange: false, //改签结果
      isDisabledConfirmTicketFlag: true,
      sameLine: true, //改签是否选择相同路线
      disabledPlaceOrder: false,//是否可以点击下单按钮
      anticipationRidingTimeList: [],//期望出行时间
      currentShiftObj: null,
      fromPage:"",//跳转过来的标识，方便返回到具体页面
      purchaseMethod:null,//购票方式：0 - 乘客购票 1-后台购票 2-快客码购票 3 API购票 4 电召下单
    };
  },
  watch: {
    searchInput: {
      handler (val) {
        if (val) {
          this.searchFun(val);
        } else {
          this.addressList = [];
        }
      },
    },
  },
  mounted () {
    const id = this.$route.params.id;
    if (id) {
      this.fromPage = this.$route.params.fromPage;
      this.queryOrderDetial(id);
    }
    this.recurSion(0);
  },
  methods: {
    computedDisable(){
      if(this.purchaseMethod === 4){
        //预留的
        return false
      }else{
        return this.statusCode != '编辑'
      }
    },
    //获取订单详情
    queryOrderDetial (orderId) {
      getOrderInfoAPI({ orderId }).then((res) => {
        if (res.code == "SUCCESS") {
          this.statusCode = "改签";
          this.purchaseMethod = res.data.purchaseMethod;
          const { isRealName, intercityChildOrderVOS,lineId } = res.data;
          this.lineId = lineId;          
          const regEn =
            /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
          //匿名改实名线路，之前的订单需要禁掉改签并提示用户
          if (isRealName == 1) {
            if (intercityChildOrderVOS[0]?.idCardNo) {
              let curIdcard = this.decryptDes(intercityChildOrderVOS[0]?.idCardNo);
              let idCardFlag = regEn.test(curIdcard);
              if (!idCardFlag) {
                this.$message.error("该线路已变更为实名制购票，请退掉原匿名票并重新购买实名票")
                this.disabledPlaceOrder = true;
              }
            }
          }
          this.createData(res.data);
          this.surplusCarryChildren = res.data.carryingChildrenNum; //手动修改携童数最大值，回显改签前的携童数
          this.queryHistoryOrder();
        }
      });
    },
    createData (item) {
      this.usedForm = item;
      this.form = {
        orderPhone: item.orderPhone,
        passengerPhone: item.passengerPhone,
        isPersonal: item.passengerPhone == item.orderPhone ? true : false,
        realName: item.isRealName == 1 ? true : false,
        startStation: item.mainStartStation,
        endStation: item.mainEndStation,
        departureDate: null,
        timeSelect: null,
        shiftIntercityId: null,
        passengerCount: 0,
        carryingChildrenNum: item.carryingChildrenNum,
        startAddress: item.startAddress,
        startPointLongitude: item.startPointLongitude,
        startPointLatitude: item.startPointLatitude,
        startLineAreaId: item.startLineAreaId,
        startStationId: item.startStationId,
        startStationType: item.startStationType,
        endAddress: item.endAddress,
        endPointLongitude: item.endPointLongitude,
        endPointLatitude: item.endPointLatitude,
        endStationId: item.endStationId,
        endStationType: item.endStationType,
        endLineAreaId: item.endLineAreaId,
        remarks: null,
        totalPrices: 0,
        ticketPricesPer: 0,
        autoSeatSelection: true,
        servicePrice: 0,
        usedPassengerVOS: [{ userName: "", idCardNo: "", segment: "" }],
        anticipationRidingTime: ""
      };
      if (item.endStationName)this.form.endStationName = item.endStationName;
      if (item.isRealName) {
        this.form.usedPassengerVOS = item.intercityChildOrderVOS
          .filter((e) => e.orderStatus == 20)
          .map((e) => {
            return {
              segment: e.segment,
              userName: e.passengerName,
              isCarryingChildren: e.carryingChildren ? true : false,
              idCardNo: this.decryptDes(e.idCardNo).replace(
                /^(.{4})(?:\d+)(.{3})$/,
                "$1******$2"
              ),
              idCardNo2: this.decryptDes(e.idCardNo),
            };
          });
        this.form.passengerCount = this.form.usedPassengerVOS.length;
      } else {
        this.form.passengerCount = item.intercityChildOrderVOS.filter(
          (e) => e.orderStatus == 20
        ).length;
        this.form.usedPassengerVOS = [
          { userName: "", idCardNo: "", segment: "" },
        ];
      }
      this.shiftList = [];
      this.upCatchList = [];
      this.addressList = [];
      this.downCatchList = [];
      this.copyDownCatchList = [];
      this.copyUpCatchList = [];
      this.addressList = [];
      this.cacheId = null;
    },
    inputPhone (val) {
      if (val.length == 11 || val == "") {
        this.queryHistoryOrder();
        this.queryCacheData();
      }
    },
    queryHistoryOrder () {
      const obj = {
        currentPage: 1,
        pageSize: 5,
        passengerPhone: this.form.passengerPhone,
      };
      queryElectricCallingPage(obj).then((res) => {
        if (res.code === "SUCCESS") {
          this.orderList = res.data.list.map((e) => {
            e.departureDate = e.departureDate.slice(5);
            return e;
          });
        }
      });
    },
    showStatus (val) {
      return this.orderStatusList.find((e) => e.value == val).label;
    },
    showCreateTime (val) {
      return moment(new Date(val)).format("YYYY-MM-DD HH:mm:ss");
    },
    selectStartStation () {
      this.form.shiftIntercityId = null;
      const data = {
        direction: 0,
        chosenStation: this.form.endStation || null,
      };
      searchLineAPI(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.startStationList = res.data.list;
        }
      });
    },
    selectEndStation () {
      this.form.shiftIntercityId = null;
      const data = {
        direction: 1,
        chosenStation: this.form.startStation || null,
      };
      searchLineAPI(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.endStationList = res.data.list;
        }
      });
    },
    //修改出发地到达地
    changeStation (type) {
      if (type == 0) {
        this.form.startAddress = null;
        this.form.endAddress = null;
      } else {
        this.form.startAddress = null;
        this.form.endAddress = null;
      }
      this.form.shiftIntercityId = null;
      this.upCatchList = [];
      this.downCatchList = [];
      this.copyDownCatchList = [];
      this.copyUpCatchList = [];
      this.cacheId = null;
      this.addressList = [];
    },
    //选择出行日期
    changeTimeSelect (val) {
      this.form.shiftIntercityId = null;
      this.upCatchList = [];
      this.downCatchList = [];
      this.copyDownCatchList = [];
      this.copyUpCatchList = [];
      this.cacheId = null;
      this.addressList = [];
      const date1 = new Date(val).getTime();
      const date2 = new Date(moment().format("YYYY-MM-DD")).getTime();
      if (date1 - date2 == 0) {
        this.form.timeSelect = 1;
      } else if (date1 - date2 == 3600 * 1000 * 24) {
        this.form.timeSelect = 2;
      } else if (date1 - date2 == 3600 * 1000 * 48) {
        this.form.timeSelect = 3;
      } else {
        this.form.timeSelect = null;
      }
    },
    changedepartureDate (val) {
      if (val == 1) {
        this.form.departureDate = moment().format("YYYY-MM-DD");
      } else if (val == 2) {
        this.form.departureDate = moment().add(1, "day").format("YYYY-MM-DD");
      } else {
        this.form.departureDate = moment().add(2, "day").format("YYYY-MM-DD");
      }
      this.form.shiftIntercityId = null;
      this.upCatchList = [];
      this.downCatchList = [];
      this.copyDownCatchList = [];
      this.copyUpCatchList = [];
      this.cacheId = null;
      this.addressList = [];
    },
    computedDepartureTime (item) {
      let timeShowValue = item.startDepartureTime;
      if (item.shiftType === 1) {
        timeShowValue = item.startDepartureTime + "-" + item.endDepartureTime;
      }
      return timeShowValue;
    },
    computedAnticipationRidingTime(item){
      let timeShowValue = item.startAnticipationRidingTime?.substring(11,16);
      if (item.shiftType === 1) {
        timeShowValue += `-${item.endAnticipationRidingTime?.substring(11,16)}`;
      }
      return timeShowValue;
    },
    // 判断是否满足选班次时间的条件
    selectShift () {
      if (!this.form.startStation) {
        this.$message.warning("请选择出发地");
      } else if (!this.form.endStation) {
        this.$message.warning("请选择到达地");
      } else if (!this.form.departureDate) {
        this.$message.warning("请选择出行日期");
      } else {
        this.shiftList = [];
        searchShiftTimeAPI({
          startStation: this.form.startStation,
          endStation: this.form.endStation,
          departureDate: this.form.departureDate,
          lineIds:this.lineId
        }).then((res) => {
          if (res.code == "SUCCESS") {
            this.shiftList = res.data.filter((item) => {
              const cur = Date.now();
              const dis = new Date(item.endDepartureDateTime).getTime();
              //判断是否有余票
              if (item.surplusTicket && cur < dis) {
                //开始时间-结束时间
                let departureTime = this.computedDepartureTime(item);
                item.label = departureTime + " - 余票" + item.surplusTicket;
                if (
                  this.statusCode == "改签" &&
                  this.usedForm.shiftIntercityId == item.shiftIntercityId
                ) {
                  item.disabled = true;
                }
                return item;
              }
            });
          }
        });
      }
    },
    getHalfHourlyTimes (startTime, endTime) {
      let y = new Date(this.form.departureDate).getFullYear();
      let m = new Date(this.form.departureDate).getMonth() + 1;
      let d = new Date(this.form.departureDate).getDate();
      let start = new Date(y + '/' + m + '/' + d + ' ' + startTime);
      let end = new Date(y + '/' + m + '/' + d + ' ' + endTime);
      let list = [];
      function formatTime (time) { //时间为个位数则补零
        return time < 10 ? '0' + time : time;
      }
      let len2 = (end.getTime() - start.getTime()) / (30 * 60 * 1000); //计算各子区间以半小时为间隔拆分后的数组长度
      for (let j = 0; j < len2; j++) {
        if (start.getTime() + 30 * 60 * 1000 <= end.getTime()) {
          //将各子区间日期按半小时递增转换为时间并存入list数组
          let ss = new Date(start.getTime() + 30 * 60 * 1000 * j);
          let flag = new Date(start.getTime() + 30 * 60 * 1000 * (j + 1)) > end;
          let ee = null;
          if (flag) {
            ee = end;
          } else {
            ee = new Date(start.getTime() + 30 * 60 * 1000 * (j + 1));
          }
          list.push({
            label: `${formatTime(ss.getHours())}:${formatTime(ss.getMinutes())} - ${formatTime(ee.getHours())}:${formatTime(ee.getMinutes())}`,
            value: `${ss.getTime()}-${ee.getTime()}`
          })
        }
      }
      return list
    },
    // 选择班次时间
    confirmShift (val) {
      this.ticketPrice = 0;
      let pop = this.shiftList
        .filter((item) => item.shiftIntercityId == val)
        .pop();
      this.currentShiftObj = { ...pop };
      this.surplusCarryChildren = pop.surplusCarryingChildrenNum;
      this.lineId = pop.lineId;
      this.form.shiftType = pop.shiftType;
      this.form.ticketPricesPer =
        pop?.discountTicketPrice || pop?.ticketPrice || 0;
      this.price = this.form.ticketPricesPer;
      const arr = [
        ...pop.lineAreaTimeTables,
        ...pop.lineAreaGetOffTables,
        ...pop.lineMidwayStations,
      ];
      this.form.anticipationRidingTime = "";
      if (pop.shiftType === 1) {
        this.anticipationRidingTimeList = this.getHalfHourlyTimes(pop.startDepartureTime, pop.endDepartureTime);
      } else {
        this.anticipationRidingTimeList = [];
      }
      if (this.usedForm.lineId) {
        //判断是否是相同线路的
        if (this.lineId != this.usedForm.lineId) {
          let text = "";
          if (this.statusCode == "改签") {
            text = "跨线路改签，需重新选择上下车点";
          } else {
            text = "跨线路班次，需重新选择上下车点";
          }
          this.$confirm(text, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.form.startAddress = "";
              this.form.startPointLongitude = null;
              this.form.startPointLatitude = null;
              this.form.endAddress = "";
              this.form.endPointLongitude = null;
              this.form.endPointLatitude = null;
              this.form.endLineAreaId = null;
              this.sameLine = false;
              configureParamsAPI({lineId:pop.lineId,channelNO: pop.channelNO}).then((res) => {
                if (res.code == "SUCCESS") {
                  this.form.servicePrice = JSON.parse(
                    res.data.SERVICE_FEE
                  ).regulationValue;
                  if (!arr.some((e) => e.floatingPrice)) {
                    this.showPrice = true;
                    this.upFloatingPrice = 0;
                    this.downFloatingPrice = 0;
                    this.computeTotal();
                  } else {
                    this.showPrice = false;
                  }
                }
              });
            })
            .catch(() => {
              this.form.shiftIntercityId = null;
              this.price = 0;
            });
        } else {
          if (this.statusCode == "改签") this.sameLine = true;
          let obj1 = arr.find((e) => e.id == this.usedForm.startLineAreaId);//上车点
          let obj2 = arr.find((e) => e.id == this.usedForm.endLineAreaId);//下车点
          if (obj1 && obj2) {
            this.upFloatingPrice = obj1.floatingPrice || 0;
            this.downFloatingPrice = obj2.floatingPrice || 0;
          } else if (obj1 && !this.usedForm.endLineAreaId) {
            //途经点
            this.upFloatingPrice = obj1.floatingPrice || 0;
            this.downFloatingPrice = 0;
            //过滤出来途经点站点信息获取价格
            const passStationObj = pop.lineMidwayStations?.find(i=>i.stationId === this.usedForm.endStationId);            
            if(passStationObj) this.price = passStationObj?.price;
          } else {
            this.$message.warning("上下车点已被修改，请重新选择");
            this.form.startAddress = "";
            this.form.endAddress = "";
            this.price = 0;
            this.form.totalPrices = 0;
            this.upFloatingPrice = 0;
            this.downFloatingPrice = 0;
          }
          configureParamsAPI({lineId:pop.lineId,channelNO: pop.channelNO}).then((res) => {
            if (res.code == "SUCCESS") {
              this.form.servicePrice = JSON.parse(
                res.data.SERVICE_FEE
              ).regulationValue;
              this.computeTotal();
            }
          });
        }
      } else {
        configureParamsAPI({lineId:pop.lineId,channelNO: pop.channelNO}).then((res) => {
          if (res.code == "SUCCESS") {
            this.form.servicePrice = JSON.parse(
              res.data.SERVICE_FEE
            ).regulationValue;
            if (!arr.some((e) => e.floatingPrice)) {
              this.showPrice = true;
              this.upFloatingPrice = 0;
              this.downFloatingPrice = 0;
              this.computeTotal();
            } else {
              this.showPrice = false;
            }
          }
        });
      }
      this.queryCacheData();
      const dataObj = {
        lineId: this.lineId,
        shiftIntercityId: this.form.shiftIntercityId,
      };
      queryAreaList({ ...dataObj, areaType: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          if (res.data.every((e) => e.areaMode == 0)) {
            this.upStationList = [];
            this.upAreaList = res.data;
          } else {
            this.upStationList = res.data.map((item) => {
              let arr = JSON.parse(item.areaContent);
              let obj = arr[0];
              return {
                name: item.name,
                address: item.name,
                detialAddress: item.name + "|" + item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
                floatingPrice: item.floatingPrice,
                price: item.price,
              };
            });
          }
        }
      });
      queryAreaList({ ...dataObj, areaType: 1 }).then((res) => {
        if (res.code == "SUCCESS") {
          if (res.data.every((e) => e.areaMode == 0)) {
            this.downStationList = [];
            this.downAreaList = res.data;
          } else {
            this.downStationList = res.data.map((item) => {
              let arr = JSON.parse(item.areaContent);
              let obj = arr[0];
              return {
                name: item.name,
                address: item.name,
                detialAddress: item.name + "|" + item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
                floatingPrice: item.floatingPrice,
                price: item.price,
              };
            });
          }
        }
      });
    },
    //查询缓存数据
    queryCacheData () {
      if (this.form.passengerPhone && this.lineId) {
        const data = {
          type: 3,
          code: this.form.passengerPhone + "-" + this.lineId,
        };
        queryKkPassengerCacheList(data).then((res) => {
          if (res.code == "SUCCESS" && res.data.length > 0) {
            const data = JSON.parse(res.data[0].cacheDate);
            this.upCatchList = data.upCatch.filter((e) => e.address);
            this.downCatchList = data.downCatch.filter((e) => e.address);
            this.copyUpCatchList = data.upCatch.filter((e) => e.address);
            this.copyDownCatchList = data.downCatch.filter((e) => e.address);
            this.cacheId = res.data[0].id;
          }
        });
      } else {
        this.upCatchList = [];
        this.downCatchList = [];
        this.copyUpCatchList = [];
        this.copyDownCatchList = [];
        this.cacheId = null;
      }
    },
    //验证身份证号
    checkIdentificationNumber (rule, value, callback) {
      if (value && value !== "") {
        const regEn =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        const arr = this.form.usedPassengerVOS.filter(
          (e) => e.idCardNo == value
        );
        const obj = this.form.usedPassengerVOS.find((e) => e.idCardNo == value);
        let text = "";
        if (obj && obj.idCardNo2) {
          text = obj.idCardNo2;
        } else {
          text = value;
        }
        if (!regEn.test(text)) {
          callback(new Error("请输入合法的身份证号码"));
        } else if (arr.length >= 2) {
          callback(new Error("乘客身份证号重复，请重新输入"));
        } else {
          callback();
        }
      } else {
        callback("请输入身份证号码");
      }
    },
    // des加密
    encryptDes (message, key = "inheritech_key") {
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
      const encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
      return encrypted.toString(); // 返回hex格式密文，如需返回base64格式：encrypted.toString()
    },
    // des解密
    decryptDes (message, key = "inheritech_key") {
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    selectSegment (val, item) {
      if (!val && item.isCarryingChildren) {
        item.isCarryingChildren = false;
      }
    },
    clickChildren (index) {
      let total = 0;
      this.form.usedPassengerVOS.map((e) => {
        e.isCarryingChildren && (total += 1);
      });
      if (total > this.surplusCarryChildren) {
        this.$alert(`携童票超出本班次剩余携童数！`, "提示", {
          confirmButtonText: "确定",
          center: true,
          callback: () => {
            this.form.usedPassengerVOS[index].isCarryingChildren = false;
          },
        });
      }
    },
    // 删除乘客
    deleteVisitor (index) {
      this.form.usedPassengerVOS.splice(index, 1);
      this.form.passengerCount = this.form.usedPassengerVOS.length;
      this.computeTotal();
    },
    // 添加新乘客
    addNew () {
      let obj = {
        userName: "",
        idCardNo: "",
        segment: "",
      };
      this.form.usedPassengerVOS.push(obj);
      this.form.passengerCount = this.form.usedPassengerVOS.length;
      this.computeTotal();
    },
    // 选择乘客人数
    selectValPer (cur, old) {
      if (cur < old && old == this.form.carryingChildrenNum) {
        this.$message.warning("若要继续减少乘客人数，请先减少携童数");
        this.$nextTick(() => {
          this.form.passengerCount = this.form.carryingChildrenNum;
        });
      }
      this.computeTotal();
    },
    //缓存上车点
    catchUpAddress (obj) {
      if (this.copyUpCatchList.length < 3) {
        this.copyUpCatchList.push({
          name: obj.name,
          address: obj.address,
          detialAddress: obj.detialAddress,
          lng: obj.lng,
          lat: obj.lat,
          stationId: obj.stationId,
          areaType: obj.areaType,
          id: obj.id,
          floatingPrice: obj.floatingPrice,
          price: obj.price,
        });
      } else {
        this.copyUpCatchList.pop();
        this.copyUpCatchList.push({
          name: obj.name,
          address: obj.address,
          detialAddress: obj.detialAddress,
          lng: obj.lng,
          lat: obj.lat,
          stationId: obj.stationId,
          areaType: obj.areaType,
          id: obj.id,
          floatingPrice: obj.floatingPrice,
          price: obj.price,
        });
      }
    },
    //缓存下车点
    catchDownAddress (obj) {
      if (this.copyDownCatchList.length < 3) {
        this.copyDownCatchList.push({
          name: obj.name,
          address: obj.address,
          detialAddress: obj.detialAddress,
          lng: obj.lng,
          lat: obj.lat,
          stationId: obj.stationId,
          areaType: obj.areaType,
          id: obj.id,
          floatingPrice: obj.floatingPrice,
          price: obj.price,
        });
      } else {
        this.copyDownCatchList.pop();
        this.copyDownCatchList.unshift({
          name: obj.name,
          address: obj.address,
          detialAddress: obj.detialAddress,
          lng: obj.lng,
          lat: obj.lat,
          stationId: obj.stationId,
          areaType: obj.areaType,
          id: obj.id,
          floatingPrice: obj.floatingPrice,
          price: obj.price,
        });
      }
    },
    selectFixedStation (val, type) {
      this.addressType = type;
      if (type === 0) {
        const obj = this.upStationList.find((i) => i.id == val);
        this.selectPoint(obj);
        this.pasteStartAddress();
        if (!this.showPrice) {
          this.form.endAddress && this.computeTotal();
        } else {
          this.computeTotal();
        }
      } else {
        const obj = this.downStationList.find((i) => i.id == val);
        this.selectPoint(obj);
        this.pasteEndAddress();
        if (!this.showPrice) {
          this.form.startAddress && this.computeTotal();
        } else {
          this.computeTotal();
        }
      }
    },
    // 地点选择
    selectAddress (val) {
      this.addressType = val;
      this.addressList = [];
      if (this.form.shiftIntercityId) {
        this.showMap = true;
        this.$nextTick(() => {
          this.loadmap(val);
        });
      } else {
        this.$message.warning("请先选择班次时间");
      }
    },
    focusAddress () {
      if (!this.form.startStation) {
        this.$message.warning("请选择出发地");
      } else if (!this.form.endStation) {
        this.$message.warning("请选择到达地");
      } else if (!this.form.departureDate) {
        this.$message.warning("请选择出行日期");
      } else if (!this.form.shiftIntercityId) {
        this.$message.warning("请选择班次时间");
      } else {
        this.addressList = [];
      }
    },
    remoteAddress (val, type) {
      this.addressType = type;
      this.addressList = [];
      if (val !== "") {
        setTimeout(() => {
          const obj = {
            searchKey: val,
            lineId: this.lineId,
            areaType: this.addressType,
          };
          searchPointAPI(obj).then((res) => {
            if (res.code === "SUCCESS") {
              this.addressList = res.data;
            }
          });
        }, 200);
      } else {
        this.addressList = [];
      }
    },
    selectAreaPoint (val) {
      const obj = this.addressList.find((i) => i.address == val);
      const data = {
        lng: obj.wgLon,
        lat: obj.wgLat,
      };
      new Promise((resolve, reject) => {
        resolve(this.regeoFun(data));
        reject("error message");
      }).then((data) => {
        if (this.addressType === 0) {
          const item = this.upAreaList.find((e) => e.id == obj.lineAreaId);
          this.selectStartPoint = {
            name: item.name,
            address: obj.address,
            detialAddress: obj.address + "|" + data.split("|")[1],
            lng: obj.wgLon,
            lat: obj.wgLat,
            areaType: item.areaType,
            stationId: item.stationId,
            id: item.id,
            floatingPrice: item.floatingPrice,
            price: item.price,
          };
          this.form.startPointLatitude = obj.wgLat;
          this.form.startPointLongitude = obj.wgLon;
          this.form.startAddress = obj.address + "|" + data.split("|")[1];
          this.form.startStationId = item.stationId;
          this.form.startStationName = item.areaType == 2 ? item.name : null;
          this.form.startStationType = item.areaType;
          this.form.startLineAreaId = item.id;
          this.upFloatingPrice = item.floatingPrice;
          if (!this.showPrice) {
            this.form.endAddress && this.computeTotal();
          } else {
            this.computeTotal();
          }
        } else {
          const item = this.downAreaList.find((e) => e.id == obj.lineAreaId);
          this.selectEndPoint = {
            name: item.name,
            address: obj.address,
            detialAddress: obj.address + "|" + data.split("|")[1],
            lng: obj.wgLon,
            lat: obj.wgLat,
            areaType: item.areaType,
            stationId: item.stationId,
            id: item.id,
            floatingPrice: item.floatingPrice,
            price: item.price,
          };
          this.form.endPointLatitude = obj.wgLat;
          this.form.endPointLongitude = obj.wgLon;
          this.form.endAddress = obj.address + "|" + data.split("|")[1];
          this.form.endStationId = item.stationId;
          this.form.endStationType = item.areaType;
          this.downFloatingPrice = item.floatingPrice;
          console.log("66666",item);
          if (item.areaType == 2) {
            this.form.endStationName = item.name;
            this.price = item.price;
            this.form.endLineAreaId = null;
          } else {
            this.form.endStationName = null;
            this.form.endLineAreaId = item.id;
          }
          if (!this.showPrice) {
            this.form.startAddress && this.computeTotal();
          } else {
            this.computeTotal();
          }
        }
      });
    },
    //实例化地图
    loadmap (type) {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      this.marker = null;
      const dataObj = {
        lineId: this.lineId,
        areaType: type,
        shiftIntercityId: this.form.shiftIntercityId,
      };
      queryAreaList(dataObj).then((res) => {
        if (res.code == "SUCCESS") {
          const data = res.data;
          data.forEach((item) => {
            let arr = JSON.parse(item.areaContent);
            if (item.areaMode == 0) {
              this.areaMode = 0;
              let path = [];
              arr.forEach((e) => {
                path.push([e.lng, e.lat, item.id]);
                this.areaPath.push(path);
              });
              //初始化polygon
              var polygon = new AMap.Polygon({
                path: path,
                strokeColor: "#FF33FF",
                strokeWeight: 6,
                strokeOpacity: 0.2,
                fillOpacity: 0.4,
                fillColor: "#1791fc",
                zIndex: 50,
              });
              this.map.add(polygon);
              this.positionPicker(dataObj.areaType, item);
            } else {
              this.areaMode = 1;
              let obj = arr[0];
              let flag = new AMap.Marker({
                position: [obj.lng, obj.lat],
              });
              let markerTxt = new AMap.Text({
                text: item.name + "|" + item.detialAddress,
                zIndex: 777,
                anchor: "center",
                offset: new AMap.Pixel(0, -60),
                style: {
                  "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                  padding: "14px",
                },
                position: [obj.lng, obj.lat],
              });
              markerTxt.setMap(this.map);
              this.map.add(flag);
              this.pointList.push({
                name: item.name,
                address: item.name,
                detialAddress: item.name + "|" + item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
                floatingPrice: item.floatingPrice,
                price: item.price,
              });
            }
            if (type == 0) {
              const position = this.selectStartPoint;
              if (position.lng && position.lat) {
                this.positionLatLng = [position.lng, position.lat];
                this.map.setCenter([position.lng, position.lat]);
              } else {
                this.map.setFitView();
              }
            } else {
              const position = this.selectEndPoint;
              if (position.lng && position.lat) {
                this.currentIndex = this.pointList.findIndex(
                  (i) => i.id == position.id
                );
                this.positionLatLng = [position.lng, position.lat];
                this.map.setCenter([position.lng, position.lat]);
              } else {
                this.map.setFitView();
              }
            }
          });
        }
      });
    },
    positionPicker (type, item) {
      let vm = this;
      this.map.on("drag", function () {
        vm.marker && vm.marker.hide();
      });
      this.map.on("dragend", function () {
        vm.marker && vm.marker.show();
      });
      const arr = JSON.parse(item.areaContent).map((i) => [i.lng, i.lat]);
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          iconStyle: {
            url: require("@/assets/images/home/from_icon2.png"),
            size: [30, 39],
            ancher: [15, 30], //锚点的位置，即被size缩放之后，图片的什么位置作为选中的位置
          },
          zIndex: 666,
        });
        vm.pickerPosition.on("success", function (positionResult) {
          const pos = positionResult.position;
          vm.positionLatLng = [pos.lng, pos.lat];
          new Promise((resolve, reject) => {
            //成功的时候调用resolve
            resolve(vm.regeoFun(pos));
            //失败的时候调用reject
            reject("error message");
          }).then((data) => {
            if (type == 0) {
              vm.selectStartPoint.lat = pos.lat;
              vm.selectStartPoint.lng = pos.lng;
              //处理成功后的逻辑
              vm.selectStartPoint.detialAddress = data;
              vm.searchAddress = data;
              vm.selectStartPoint.address = data.split("|")[0];
              vm.selectStartPoint.name = item.name;
              const inRing = AMap.GeometryUtil.isPointInRing(
                [pos.lng, pos.lat],
                arr
              );
              if (inRing) {
                vm.selectStartPoint.stationId = item.stationId;
                vm.selectStartPoint.areaType = item.areaType;
                vm.selectStartPoint.id = item.id;
                vm.selectStartPoint.floatingPrice = item.floatingPrice;
                vm.selectStartPoint.price = item.price;
              }
            } else {
              vm.selectEndPoint.lat = pos.lat;
              vm.selectEndPoint.lng = pos.lng;
              vm.selectEndPoint.name = item.name;
              vm.selectEndPoint.detialAddress = data;
              vm.selectEndPoint.address = data.split("|")[0];
              vm.searchAddress = data;
              const inRing = AMap.GeometryUtil.isPointInRing(
                [pos.lng, pos.lat],
                arr
              );
              if (inRing) {
                vm.selectEndPoint.stationId = item.stationId;
                vm.selectEndPoint.areaType = item.areaType;
                vm.selectEndPoint.floatingPrice = item.floatingPrice;
                vm.selectEndPoint.id = item.id;
                vm.selectEndPoint.price = item.price;
              }
            }
            if (!vm.marker) {
              vm.marker = new AMap.Text({
                text: vm.searchAddress,
                zIndex: 777,
                anchor: "center",
                offset: new AMap.Pixel(0, -60),
                style: {
                  "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                  padding: "14px",
                },
                position: vm.positionLatLng,
              });
              vm.marker.setMap(vm.map);
            } else {
              vm.marker.setPosition(vm.positionLatLng);
              vm.marker.setText(vm.searchAddress);
            }
          });
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    async regeoFun (position) {
      const res = await axios
        .get(
          `https://restapi.amap.com/v3/geocode/regeo?output=json&location=${position.lng},${position.lat}&key=d85b28ec7a166619a217cfe7cc0d700b&radius=1000&extensions=all`
        )
        .then((res) => {
          const addressComponent = res.data.regeocode.addressComponent;
          const abbreviation =
            res.data.regeocode.aois[0]?.name || addressComponent.district; //简称
          const detailedAddress =
            addressComponent.province +
            addressComponent.city +
            addressComponent.district +
            addressComponent.streetNumber.street +
            addressComponent.streetNumber.number; //详细地址
          return abbreviation + "|" + detailedAddress;
        });
      return res;
    },
    selectCatchAddress (item, type) {
      if (type == 0) {
        this.selectStartPoint = item;
        this.form.startAddress = item.detialAddress;
        this.form.startPointLongitude = item.lng;
        this.form.startPointLatitude = item.lat;
        this.form.startStationId = item.stationId;
        this.form.startStationType = item.areaType;
        this.form.startLineAreaId = item.id;
        this.upFloatingPrice = item.floatingPrice;
        if (!this.showPrice) {
          this.form.endAddress && this.computeTotal();
        } else {
          this.computeTotal();
        }
      } else {
        this.selectEndPoint = item;
        this.form.endAddress = item.detialAddress;
        this.form.endPointLongitude = item.lng;
        this.form.endPointLatitude = item.lat;
        this.form.endStationId = item.stationId;
        this.form.endStationType = item.areaType;
        this.form.endLineAreaId = item.id;
        this.downFloatingPrice = item.floatingPrice;
        if (item.areaType == 2) {
          this.form.endStationName = item.name;
          this.price = item.price;
        } else {
          this.form.endStationName = null;
        }
        if (!this.showPrice) {
          this.form.startAddress && this.computeTotal();
        } else {
          this.computeTotal();
        }
      }
    },
    // 点击搜索的地址
    searchClick (item) {
      this.positionLatLng = [item.wgLon, item.wgLat];
      this.map.setZoom(16);
      this.map.setCenter(this.positionLatLng);
      this.addressList = [];
      this.searchInput = null;
      this.searchAddress = item.address;
    },
    // 输入地名搜索地址
    searchFun (val) {
      const obj = {
        searchKey: val,
        lineId: this.lineId,
        areaType: this.addressType,
      };
      searchPointAPI(obj).then((res) => {
        this.addressList = res.data;
      });
    },
    // 地图选择固定点
    clickPoint (item, index) {
      this.currentIndex = index;
      this.positionLatLng = [item.lng, item.lat];
      this.map.setCenter(this.positionLatLng);
      this.selectPoint(item);
    },
    selectPoint (item) {
      if (this.addressType == 0) {
        this.selectStartPoint.lat = item.lat;
        this.selectStartPoint.lng = item.lng;
        this.selectStartPoint.name = item.name;
        this.selectStartPoint.address = item.address;
        this.selectStartPoint.detialAddress = item.detialAddress;
        this.selectStartPoint.stationId = item.stationId;
        this.selectStartPoint.id = item.id;
        this.selectStartPoint.areaType = item.areaType;
        this.selectStartPoint.floatingPrice = item.floatingPrice;
        this.selectStartPoint.price = item.price;
      } else {
        this.selectEndPoint.lng = item.lng;
        this.selectEndPoint.lat = item.lat;
        this.selectEndPoint.name = item.name;
        this.selectEndPoint.address = item.address;
        this.selectEndPoint.detialAddress = item.detialAddress;
        this.selectEndPoint.stationId = item.stationId;
        this.selectEndPoint.areaType = item.areaType;
        this.selectEndPoint.floatingPrice = item.floatingPrice;
        this.selectEndPoint.price = item.price;
        this.selectEndPoint.id = item.id;
      }
    },
    //赋值上车点
    pasteStartAddress () {
      this.form.startPointLatitude = this.selectStartPoint.lat;
      this.form.startPointLongitude = this.selectStartPoint.lng;
      this.form.startAddress = this.selectStartPoint.detialAddress;
      this.form.startStationId = this.selectStartPoint.stationId;
      if (this.selectStartPoint.areaType == 2) {
        this.form.startStationName = this.selectStartPoint.name;
      } else {
        this.form.startStationName = null;
      }
      this.form.startStationType = this.selectStartPoint.areaType;
      this.form.startLineAreaId = this.selectStartPoint.id;
      this.upFloatingPrice = this.selectStartPoint.floatingPrice;
    },
    //赋值下车点
    pasteEndAddress () {
      this.form.endPointLatitude = this.selectEndPoint.lat;
      this.form.endPointLongitude = this.selectEndPoint.lng;
      this.form.endAddress = this.selectEndPoint.detialAddress;
      this.form.endStationId = this.selectEndPoint.stationId;
      this.form.endStationType = this.selectEndPoint.areaType;
      if (this.selectEndPoint.areaType == 2) {
        this.form.endLineAreaId = null;
        this.form.endStationName = this.selectEndPoint.name;
        this.price = this.selectEndPoint.price;
      } else {
        this.form.endLineAreaId = this.selectEndPoint.id;
        this.form.endStationName = null;
        this.price = this.form.ticketPricesPer;
      }
      this.downFloatingPrice = this.selectEndPoint.floatingPrice;
    },
    // 确认上车点下车点
    confirmAddress () {
      if (this.areaMode == 0) {
        let inRing = this.areaPath.findIndex((res) =>
          AMap.GeometryUtil.isPointInRing(this.positionLatLng, res)
        );
        if (inRing > -1) {
          if (this.addressType === 0) {
            this.pasteStartAddress();
            if (!this.showPrice) {
              this.form.endAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          } else {
            this.pasteEndAddress();
            if (!this.showPrice) {
              this.form.startAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          }
          this.cancelBtn();
        } else {
          this.marker && this.marker.hide();
          this.$message.warning("该地点不在可选区域内！");
        }
      } else {
        if (this.positionLatLng) {
          if (this.addressType === 0) {
            this.pasteStartAddress();
            if (!this.showPrice) {
              this.form.endAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          } else {
            this.pasteEndAddress();
            if (!this.showPrice) {
              this.form.startAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          }
          this.cancelBtn();
        } else {
          this.$message.warning("请选择固定点！");
        }
      }
      this.marker = null;
    },
    cancelBtn () {
      this.showMap = false;
      this.marker = null;
      this.searchAddress = null;
      this.areaPath = [];
      this.addressList = [];
      this.searchInput = null;
      this.positionLatLng = null;
      this.pointList = [];
      this.currentIndex = -1;
      if (this.map) this.map.destroy();
    },
    // 计算总价
    computeTotal () {
      this.price = parseFloat(this.price);
      this.upFloatingPrice = parseFloat(this.upFloatingPrice || 0);
      this.downFloatingPrice = parseFloat(this.downFloatingPrice || 0);
      let { servicePrice, passengerCount, usedPassengerVOS } = this.form;
      passengerCount = passengerCount || usedPassengerVOS.length;
      this.ticketPrice = (
        this.price +
        this.upFloatingPrice +
        this.downFloatingPrice
      ).toFixed(2);
      const total =
        (this.price +
          this.upFloatingPrice +
          this.downFloatingPrice +
          servicePrice) *
        passengerCount;
      this.form.totalPrices = total.toFixed(2);
    },
    cancelOrder () {      
      this.returnFunHistory();
      return
      this.statusCode = "编辑";
      this.sameLine = true;
      this.clearFun();
    },
    // 立即下单
    placeOrder () {
      if (this.loading2) return;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let obj = {
            businessType: 1,
            purchaseMethod: 4,
            startPointLongitude: this.form.startPointLongitude,
            endPointLongitude: this.form.endPointLongitude,
            endPointLatitude: this.form.endPointLatitude,
            startPointLatitude: this.form.startPointLatitude,
            startAddress: this.form.startAddress,
            endAddress: this.form.endAddress,
            passengerPhone: this.form.passengerPhone,
            orderPhone: !this.form.isPersonal
              ? this.form.orderPhone
              : this.form.passengerPhone,
            shiftIntercityId: this.form.shiftIntercityId,
            totalPrices: 0,
            passengerCount: this.form.passengerCount,
            startStationId: this.form.startStationId,
            startLineAreaId: this.form.startLineAreaId,
            startStationType: this.form.startStationType,
            endStationId: this.form.endStationId,
            endStationType: this.form.endStationType,
            payablePrices: this.form.totalPrices,
            remarks: this.form.remarks,
            realName: this.form.realName,
          };
          //流水班需要再增加期望出行时间
          if (this.form.shiftType === 1) {
            obj.startAnticipationRidingTime = this.form.anticipationRidingTime?.split("-")[0];
            obj.endAnticipationRidingTime = this.form.anticipationRidingTime?.split("-")[1];
          } else {
            //定班：取班次时间
            let timestamp = new Date(this.currentShiftObj.startDepartureDateTime).getTime();
            obj.startAnticipationRidingTime = timestamp;
            obj.endAnticipationRidingTime = timestamp;
          }

          if (this.form.endLineAreaId)
            obj.endLineAreaId = this.form.endLineAreaId;
          if (this.form.endStationName)
            obj.endStationName = this.form.endStationName;
          if (!this.form.realName) {
            obj.carryingChildrenNum = this.form.carryingChildrenNum;
          } else {
            let arr = this.deepClone(this.form.usedPassengerVOS);
            let total = 0;
            obj.usedPassengerVOS = arr.map((item) => {
              if (item.idCardNo2) {
                item.idCardNo = this.encryptDes(item.idCardNo2);
              } else {
                item.idCardNo = this.encryptDes(item.idCardNo);
              }
              item.isCarryingChildren && (total += 1);
              return {
                idCardNo: item.idCardNo,
                segment: item.segment,
                userName: item.userName,
              };
            });
            obj.carryingChildrenNum = total;
          }
          //改签
          if (this.statusCode == "改签") {
            this.$confirm("确定修改为新的班次吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {              
              const data = {
                orderIds: this.usedForm.intercityChildOrderVOS.map((i) => i.id),
                refundProportion: 0,
                reason: "取消订单",
              };
              cancelTicket(data).then((res) => {
                if (res.code == "SUCCESS") {
                  this.loading2 = true;
                  this.submitOrder(obj,true);
                }
              });
            });
          } else {
            this.loading2 = true;
            this.submitOrder(obj);
          }
          //下单
        }
      });
    },
    //缓存上下车点
    saveOrUpdateAddress (obj) {
      this.sameLine = true;
      this.loading2 = false;
      //缓存快捷上下车点
      const flag1 = this.upCatchList.some(
        (e) =>
          e.lng == this.selectStartPoint.lng &&
          e.lat == this.selectStartPoint.lat &&
          e.detialAddress.split("|")[1] ==
          this.selectStartPoint.detialAddress.split("|")[1]
      );
      const flag2 = this.downCatchList.some(
        (e) =>
          e.lng == this.selectEndPoint.lng &&
          e.lat == this.selectEndPoint.lat &&
          e.detialAddress.split("|")[1] ==
          this.selectEndPoint.detialAddress.split("|")[1]
      );
      if (!flag1) {
        this.catchUpAddress(this.selectStartPoint);
      }
      if (!flag2) {
        this.catchDownAddress(this.selectEndPoint);
      }
      const data = {
        type: 3,
        code: obj.passengerPhone + "-" + this.lineId,
        cacheDate: {
          upCatch: this.copyUpCatchList,
          downCatch: this.copyDownCatchList,
        },
      };
      if (this.cacheId) {
        data.id = this.cacheId;
      }
      saveOrUpdate(data).then((res) => { });
      this.clearFun();
    },
    submitOrder (obj,flag) {     
      placeOrderAPI(obj)
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("下单成功！");
            this.statusCode = "编辑";
            this.saveOrUpdateAddress(obj);
            if(flag){
              this.$nextTick(()=>{
                this.returnFunHistory();
              })
            }
          } else {
            this.loading2 = false;
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    //复用历史订单
    useHistoryOrder (item) {
      this.statusCode = "编辑";
      this.createData(item);
    },
    refundTicket (item) {
      if (item.totalPrices) {
        this.showRefund = true;
        this.tableData = item.intercityChildOrderVOS;
      } else {
        this.$confirm("确认退票？", "提示", {
          center: true,
        }).then(() => {
          const obj = {
            orderIds: item.intercityChildOrderVOS.map((i) => i.id),
            refundProportion: 0,
            reason: "取消订单",
          };
          cancelTicket(obj).then((res) => {
            if (res.code == "SUCCESS") {
              this.queryHistoryOrder();
              this.$message.success("退票成功");
            }
          });
        });
      }
    },
    //退票比例
    recurSion (num) {
      if (num < 90) {
        let Numb = (num += 5);
        this.serviceChargeList.push({
          label: Numb,
          value: Numb,
        });
        this.recurSion(Numb);
      } else {
        return;
      }
    },
    selectRefundList (selection) {
      this.refundForm.ticketPrice = 0;
      this.refundList = [];
      this.refundChildrenNum = 0;
      if (selection.length) {
        selection.forEach((e) => {
          this.refundForm.ticketPrice +=
            e.preferentialType === 0 ? e.ticketPricesPer : e.totalPrices;
          this.refundList.push(e.id);
          this.refundChildrenNum += e.carryingChildren;
        });
        this.refundForm.refundTotal = this.refundForm.serviceCharge
          ? (
            (this.refundForm.ticketPrice *
              (100 - this.refundForm.serviceCharge)) /
            100
          ).toFixed(2)
          : this.refundForm.ticketPrice.toFixed(2);
      } else {
        this.refundForm.ticketPrice = 0;
        this.refundForm.refundTotal = 0;
      }
    },
    refundServiveFeeChange (val) {
      this.refundForm.refundTotal = (
        (this.refundForm.ticketPrice * (100 - val)) /
        100
      ).toFixed(2);
    },
    refundCloseFun () {
      this.refundForm.refundTotal = 0;
      this.refundForm.ticketPrice = 0;
      this.refundForm.serviceCharge = 0;
      this.showRefund = false;
      this.loading = false;
      this.refundList = [];
    },
    refundConfirmFun () {
      if (!this.refundForm.refundTotal) {
        this.$message.warning("请选择退票订单！");
        return;
      }
      this.loading = true;
      if (this.refundChildrenNum) {
        this.$confirm(
          `包含${this.refundChildrenNum}张携童票，确认退订`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            center: true,
          }
        ).then(() => {
          this.confirmOpera();
        });
      } else {
        this.confirmOpera();
      }
    },
    confirmOpera () {
      cancelTicket({
        orderIds: this.refundList,
        refundProportion: this.refundForm.serviceCharge,
        reason: this.refundForm.reasonForRefund,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message.success("退票成功!");
          this.loading = false;
          this.showRefund = false;
          this.refundForm.reasonForRefund = null;
          this.queryHistoryOrder();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeTicket (item) {
      if (!item.totalPrices) {
        this.statusCode = "改签";
        this.createData(item);
        this.$message.warning("该订单未支付，修改出行日期重新下单即可");
      } else {
        this.$route.meta.title = "改签";
        this.ticketChangeForm = item;
        this.isShowTicketChange = true;
      }
    },
    //清空数据操作
    clearFun () {
      this.orderList = [];
      this.upCatchList = [];
      this.downCatchList = [];
      this.copyUpCatchList = [];
      this.addressList = [];
      this.copyDownCatchList = [];
      this.shiftList = [];
      this.cacheId = null;
      this.usedForm = {};
      this.form = {
        orderPhone: null,
        passengerPhone: null,
        isPersonal: true,
        realName: false,
        startStation: null,
        endStation: null,
        departureDate: moment().format("YYYY-MM-DD"),
        timeSelect: 1,
        shiftIntercityId: null,
        passengerCount: 1,
        carryingChildrenNum: 0,
        usedPassengerVOS: [{ userName: "", idCardNo: "", segment: "" }],
        startAddress: null,
        startPointLongitude: null,
        startPointLatitude: null,
        startLineAreaId: null,
        startStationId: null,
        startStationType: null,
        endAddress: null,
        endPointLongitude: null,
        endPointLatitude: null,
        endStationId: null,
        endStationType: null,
        endLineAreaId: null,
        remarks: null,
        totalPrices: 0,
        ticketPricesPer: 0,
        autoSeatSelection: true,
        servicePrice: 0,
        anticipationRidingTime: ""
      };
    },
    //返回按钮
    returnFun (val) {
      if (val == "request") this.clearFun();
      this.$route.meta.title = "电召订票";
      this.ticketChangeActive = 0;
      this.isShowTicketChange = false;
    },
    //返回历史查询页面
    returnFunHistory () {
      if(this.fromPage === "showPassenger"){
        this.$router.go(-1);
      }else{
        this.$router.push("/projectTravel/historyQuery");
      }      
    },
    placeOrder2 (val) {
      if (val == "SUCCESS") {
        this.isTicketChange = true;
      } else {
        this.isTicketChange = false;
      }
      this.ticketChangeActive = 2;
    },
    //监听订单金额的变化
    watchOrderAmount (val) {
      this.isDisabledConfirmTicketFlag = val;
    },
    //步骤条下一步
    nextFun () {
      this.ticketChangeActive = 1;
    },
    //步骤条上一步
    previousFun () {
      this.$refs.phoneOrderDetialRef.$refs.ruleForm.resetFields();
      this.$refs.phoneOrderDetialRef.getTicketChangeFormFun();
      this.ticketChangeActive = 0;
    },
    //确认改签
    confirmTicketChangeFun () {
      this.$refs.phoneOrderDetialRef.placeOrder();
    },
  },
};
</script>

<style lang="scss" scoped>
.telebooking {
  width: 100%;
  height: calc(100% - 32px - 50px) !important;
  margin-top: 50px;
  .calling {
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    border-radius: 4px;
    header {
      font-size: 15px;
      margin-bottom: 15px;
    }
    /deep/.el-form-item {
      margin-bottom: 16px;
    }
    .box {
      flex: 1;
      display: flex;
      overflow: auto;
      .label {
        background-color: #f7f8f9;
        border-left: 2px solid #336ffe;
        padding: 5px 0px 5px 21px;
        margin-bottom: 8px;
      }
      .form {
        width: 60%;
        height: 100%;
        padding: 0 10px;
        .its_gy_delete {
          color: red;
          margin-left: 10px;
          cursor: pointer;
          font-size: 18px;
        }
        .add {
          width: 470px;
          height: 32px;
          border: #dcdedf dashed 1px;
          margin-bottom: 10px;
          cursor: pointer;
          margin-left: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgb(131, 131, 131);
        }
        .editbox {
          display: flex;
          .its_gy_map {
            color: #336ffe;
            margin-left: 10px;
            cursor: pointer;
            font-size: 18px;
          }
        }
        ul {
          display: flex;
          line-height: 22px;
          li {
            margin-right: 8px;
            background-color: #cedcff;
            font-size: 12px;
            padding: 2px 8px;
            border-radius: 40px;
            cursor: pointer;
            height: max-content;
          }
        }
        .submit {
          background-color: #f3f6ff;
          height: 64px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 30px;
          .btns {
            height: 100%;
            display: flex;
            align-items: center;
            button {
              height: 48px;
              width: 100px;
              border: none;
              width: 100px;
              font-size: 16px;
              margin-right: 10px;
              border-radius: 4px;
            }
            .cancel {
              background-color: #dcdedf !important;
              color: #000 !important;
            }
            .order {
              background-color: #336ffe;
              color: #fff;
            }
          }
          .total {
            height: 100%;
            display: flex;
            align-items: center;
            height: 38px;
            text-align: right;
            .item {
              height: 100%;
              color: #858585;
              font-size: 14px;

              .price {
                color: red;
                font-size: 24px;
                margin-right: 30px;
              }
            }
          }
        }
      }
      .table {
        width: 40%;
        height: 100%;
        background-color: #f2f2f2;
        display: flex;
        flex-direction: column;
        overflow: auto;
        .empty1 {
          text-align: center;
          font-size: 16px;
          margin-top: 100px;
          color: #999999;
        }
        .empty2 {
          text-align: center;
          color: #ff8627;
          margin-top: 20px;
        }
        .order-list {
          flex: 1;
          overflow: auto;
          padding: 12px 12px 0 12px;
          .item {
            border-radius: 12px;
            background-color: #fff;
            padding: 12px;
            margin-bottom: 12px;
            .header {
              display: flex;
              align-items: center;
              .sign {
                height: 40px;
                color: #fff;
                font-size: 12px;
                border-radius: 4px;
                text-align: center;
                line-height: 40px;
                margin-right: 15px;
                padding: 0 18px;
              }
              .line {
                flex: 1;
                .name {
                  font-size: 14px;
                  font-weight: bold;
                  margin-bottom: 7px;
                }
                .status {
                  font-size: 12px;
                }
              }
              .btns {
                flex: 1;
                display: flex;
                justify-content: flex-end;
              }
            }
            .info {
              display: flex;
              margin-top: 15px;
              font-size: 14px;
              label {
                width: 75px;
              }
              p {
                color: #0b1838;
              }
            }
          }
        }
      }
    }
  }
  .map {
    position: relative;
    height: 500px;
    .searchbox {
      position: absolute;
      top: 14px;
      left: 10px;
      width: 300px;
      z-index: 1200;
      input {
        width: 212px;
        height: 28px;
        border: #d6d6d6 solid 1px;
        flex: 1;
        outline: none;
        margin-right: 16px;
        padding-left: 10px;
      }
      .pointlist {
        max-height: 250px;
        background-color: #fff;
        overflow: auto;
        margin-top: 10px;
        p {
          padding: 7px 10px;
          cursor: pointer;
          &:hover {
            background-color: #e4e4e4;
          }
        }
      }
    }
    .select {
      width: 312px;
      position: absolute;
      z-index: 1100;
      left: 10px;
      background-color: #fff;
      border-radius: 5px;
      header {
        font-size: 15px;
        color: black;
        padding: 10px 10px 0;
      }
      p {
        margin-top: 10px;
        padding: 0 10px 10px;
      }
      .point {
        margin: 10px 0;
        .item {
          border-radius: 5px;
          cursor: pointer;
          padding: 10px 10px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
          .detial {
            margin-left: 15px;
            font-size: 12px;
          }
        }
        .item1 {
          color: white;
          background-color: #336ffe;
        }
      }
    }
    #container {
      height: 500px;
    }
  }
  .refund-box {
    .feeBox {
      display: flex;
      margin: 40px 0;
      justify-content: space-between;

      p {
        padding-bottom: 10px;
        font-size: 14px;
      }

      /deep/.el-input__inner {
        width: 300px;
      }
    }
    .reasonBox {
      margin: 40px 0;

      p {
        padding-bottom: 10px;
        font-size: 14px;
      }

      /deep/.el-input__inner {
        width: 300px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .desc {
      color: red;
      margin-right: 30px;
      font-size: 15px;
    }
  }
}
.exit {
  width: 32px;
  height: 32px;
  color: #333333;
  font-size: 15px;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  position: fixed;
  top: 80px;

  i {
    cursor: pointer;
  }
}
.ticket-change-page {
  margin-top: 50px;
  position: relative;
  height: calc(100% - 32px - 50px) !important;

  .ticket-change {
    width: 100%;
    height: calc(100% - 32px);
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;

    .title {
      width: 100%;
      height: 56px;
      line-height: 56px;
      padding-left: 10px;
      margin-bottom: 50px;
      border-bottom: 1px solid #dcdedf;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      box-sizing: border-box;
    }

    .button-box {
      border-top: 1px solid #dcdedf;
      padding-top: 16px;
      display: flex;
      justify-content: center;
    }

    .result-box {
      width: 100%;
      height: 560px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;

      div {
        display: flex;
        align-items: center;

        span {
          margin-left: 12px;
        }
      }

      .success-text {
        color: #2da641;
      }

      .error-text {
        color: #d40000;
      }
    }

    ::v-deep .is-text {
      border: none;
      color: #ffffff;
    }

    ::v-deep .el-step__main {
      .is-wait {
        color: #969799;
      }

      .el-step__description {
        color: #969799;
      }

      .el-step__title {
        color: #303133;
      }
    }

    ::v-deep .is-process {
      .is-text {
        background: #336ffe;
      }
    }

    ::v-deep .is-wait {
      .is-text {
        background: #c8c9cc;
      }
    }

    ::v-deep .is-success {
      .is-text {
        border: 2px solid #336ffe;
        color: #336ffe;
      }

      .el-step__line {
        border-color: #336ffe;
        background-color: #336ffe;
      }
    }
  }
}
</style>
